import { Box } from "@chakra-ui/react";
import React from "react";
import PageHeader from "./page-header";

import topBackground from "@images/home/top-bkg.png";

const PageHeaderContainer = ({ children }) => {
  return (
    <Box
      w="100%"
      minHeight="100vh"
      bgImage={`url(${topBackground})`}
      bgRepeat="no-repeat"
      bgPos="center"
    >
      <PageHeader />
      {children}
    </Box>
  );
};
export default PageHeaderContainer;

import React from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Image,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

import logo from "@images/logo.svg";

const Nav = (props) => {
  return (
    <HStack {...props} spacing={4} w="100%" wrap>
      <Button variant="link">Services</Button>
      <Button variant="link">About</Button>
      <Button variant="link">Case Studies</Button>
      <Button variant="link">Careers</Button>
      <Button variant="link">Blogs</Button>
    </HStack>
  );
};
const PageFooter = () => {
  return (
    <Box pt={[6, null, 12]} pb={6} w="100%" fontSize={{ lg: "lg", xl: "lg" }}>
      <Container maxW="container.xl">
        <SimpleGrid
          columns={{ sm: 1, md: 1, lg: 2, xl: 2 }}
          spacing={[6, null, 24]}
        >
          <Box>
            <VStack spacing={6} alignItems="flex-start">
              <Nav flex={1} />
              <Box>
                <Image src={logo} alt="logo" />
              </Box>
              <HStack spacing={8} justify="center">
                <FontAwesomeIcon size="lg" icon={faMapMarkerAlt} />
                <Text maxW="500px">
                  Adelle MOffice Building, 373-375 Nguyen Trong Tuyen, Ward 2,
                  Tan Binh District, Ho Chi Minh City, Vietnam.
                </Text>
              </HStack>
              <HStack spacing={8}>
                <PhoneIcon />
                <Text maxW="500px">+84 28 6681 5808</Text>
              </HStack>
              <HStack spacing={8}>
                <EmailIcon />
                <Text maxW="500px">contact@zamo.io</Text>
              </HStack>
            </VStack>
          </Box>
          <Box>
            <VStack
              h="100%"
              spacing={[6, null, 12]}
              alignItems={["flex-start", null, "flex-end"]}
            >
              <HStack spacing={6}>
                <FontAwesomeIcon size="2x" icon={faFacebook} />
                <FontAwesomeIcon size="2x" icon={faTwitter} />
              </HStack>
              <FormControl id="email">
                <FormLabel>Get In Touch! Join our Newsletters</FormLabel>
                <HStack>
                  <Input type="email" />
                  <Button color="yellow.500" type="submit">
                    Subscribe
                  </Button>
                </HStack>
                <FormHelperText>We'll never share your email.</FormHelperText>
              </FormControl>
              <Spacer />
              <Text>© 2020 ZAMO Co. All Rights Reserved.</Text>
            </VStack>
          </Box>
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default PageFooter;

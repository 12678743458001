import { Box} from "@chakra-ui/react";
import Fonts from "../Fonts";
import React from "react";
import PageFooter from "./page-footer";

const PrimaryPageContainer = ({ children }) => {
  return (
    <Box>
      <Fonts />
      {children}
      <PageFooter />
    </Box>
  );
};
export default PrimaryPageContainer;

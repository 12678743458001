import React from "react";
import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Brandon';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('/fonts/Brandon_reg.otf') format('opentype');
      }
      @font-face {
        font-family: 'Brandon';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url('/fonts/Brandon_med.otf') format('opentype');
      }
      @font-face {
        font-family: 'Brandon';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('/fonts/Brandon_bld.otf') format('opentype');
      }
      `}
  />
);

export default Fonts;

import React from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  Spacer,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";

import logo from "@images/logo.svg";

const Nav = (props) => {
  const commonBtnProps = {
    _hover: { color: "yellow.500" },
    fontWeight: 500,
  };
  return (
    <HStack
      {...props}
      spacing={6}
      display={{ base: "none", lg: "flex", xl: "flex" }}
    >
      <Button variant="link" {...commonBtnProps}>
        HOME
      </Button>
      <Button variant="link" {...commonBtnProps}>
        SERVICES
      </Button>
      <Button variant="link" {...commonBtnProps}>
        ABOUT US
      </Button>
      <Button variant="link" {...commonBtnProps}>
        CASE STUDIES
      </Button>
      <Button variant="link" {...commonBtnProps}>
        CAREERS
      </Button>
      <Button variant="link" {...commonBtnProps}>
        BLOGS
      </Button>
    </HStack>
  );
};
const HamburgerMenu = () => {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<HamburgerIcon />}
        variant="outline"
        borderColor="yellow.500"
      />
    </Menu>
  );
};

const PageHeader = (props) => {
  return (
    <Container maxW="container.xl" {...props}>
      <Flex w="100%" pt={4} pb={4} align="center">
        <Box>
          <Image src={logo} alt="logo" />
        </Box>
        <Spacer />
        <Nav flex={1} />
        <Spacer />
        <HStack spacing={8}>
          <Button
            display={{ base: "none", lg: "inline-flex", xl: "inline-flex" }}
          >
            CONTACT US
          </Button>
          <HamburgerMenu />
        </HStack>
      </Flex>
    </Container>
  );
};

export default PageHeader;

import React from "react";
import {
  Box,
  Button,
  Container,
  Image,
  Link,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import {
  PageHeaderContainer,
  PrimaryPageContainer,
} from "@components/page-container";

import officeBkg from "@images/home/office-bkg.png";
import jobBkg from "@images/home/jobs-bkg.png";

export default function IndexPage() {
  return (
    <PrimaryPageContainer>
      <PageHeaderContainer>
        <Container maxW="container.xl" centerContent>
          <Box pt={[20, null, 40]} pb={[10, null, 6]}>
            <Text
              fontSize={["2xl", null, "4xl"]}
              fontWeight="bold"
              textTransform="uppercase"
              letterSpacing="12px"
              color="yellow.500"
            >
              Since 2016
            </Text>
          </Box>
          <Wrap
            p={[1, null, 10, 20]}
            justify="center"
            fontSize={{
              base: "40px",
              sm: "70px",
              md: "70px",
              lg: "70px",
              xl: "90px",
            }}
            letterSpacing="9px"
            fontWeight={900}
          >
            <WrapItem>
              <Wrap spacing={["20px", null, "40px"]} justify="center">
                <WrapItem>
                  <Box>LET'S</Box>
                </WrapItem>
                <WrapItem>
                  <Box color="yellow.500">SUCCEED</Box>
                </WrapItem>
              </Wrap>
            </WrapItem>
            <WrapItem>
              <Box>TOGETHER</Box>
            </WrapItem>
          </Wrap>
          <Box p={8} w={["100%", "100%", "50%"]}>
            <Text
              fontSize={["lg", null, "xl", "2xl"]}
              textAlign="justify"
              color="gray.200"
            >
              Our Mission is striving to strengthen our client’s business growth
              by providing the exceptional design and agile development with the
              competitive advantages in the market
            </Text>
          </Box>
        </Container>
      </PageHeaderContainer>
      <Box pt={[8, 16, 24]} width="100%" bg="white">
        <Container maxW="container.xl" centerContent>
          <Text
            px={4}
            casing="uppercase"
            color="gray.700"
            textAlign="center"
            letterSpacing={2.5}
            fontWeight="bold"
            fontSize={[24, 24, 32, 48]}
          >
            we <Link color="yellow.500">serve clients</Link> with the premier
            software development & consultancy
          </Text>

          <Text
            mt={[8, 16]}
            color="gray.500"
            letterSpacing={0.53}
            textAlign="center"
            lineHeight={["28px", null, "44px"]}
            fontSize={[16, 24]}
          >
            <Link color="gray.700" fontWeight="bold">
              ZAMO
            </Link>
            , stands for{" "}
            <Link color="yellow.500" fontWeight="bold">
              Zest - Aspiration - Modesty - Optimism
            </Link>
            , is a software development company established in 2016. We aspire
            to serving customers the highest level of beneficial IT solutions
            and services. We greatly concentrate on providing the strong
            collaboration with clients and contribute to their sustainable
            success.
          </Text>
        </Container>
        <Box px={[0, 4, null, 24]} py={[4, 16]} w="100%">
          <Image
            w="100%"
            objectFit="cover"
            src={officeBkg}
            alt="office background"
          />
        </Box>
      </Box>
      <Box
        py={[8, 16, 24]}
        bg="white"
        bgImage={`url(${jobBkg})`}
        bgRepeat="no-repeat"
        bgPos="center"
      >
        <Container maxW="container.lg" centerContent>
          <Text
            color="gray.700"
            fontSize={[24, 24, 32, 48]}
            textAlign="center"
            fontWeight="bold"
          >
            JOIN OUR TEAM
          </Text>
          <Text
            mt={[8, 16]}
            color="gray.500"
            letterSpacing={0.53}
            textAlign="center"
            lineHeight={["28px", null, "44px"]}
            fontSize={[16, 24]}
          >
            Do you enjoy challenges? ZAMO is searching for talented and dynamic
            people to join on our new adventures together.
          </Text>

          <Button
            variant="link"
            mt={8}
            fontSize="md"
            color="gray.500"
            textDecor="underline #ffcb41"
          >
            VIEW CURRENT OPENINGS
          </Button>
          <Text
            mt={[8, 24]}
            color="yellow.500"
            fontSize={[20, null, 32, 48]}
            textAlign="center"
            fontWeight="bold"
          >
            START YOUR PROJECTS WITH US!
          </Text>
          <Button mt={4} fontSize="sm" bg="gray.700" color="yellow.500">
            CONTACT US
          </Button>
        </Container>
      </Box>
    </PrimaryPageContainer>
  );
}
